import axios from 'axios';
import { REACT_APP_APIURL } from '../global/Environment';
//import { getAPIResponseError, isEmpty } from '../global/Helpers';

// This is now serving as a general site config service -- not just pricing
// So, we need to rename it to something like siteConfigService

let response = null;

async function fetchData() {

	if(response) {
        //If the return value of an async function is not explicitly a promise, 
        //it will be implicitly wrapped in a promise
        return response;
	}
	return axios.get(`${REACT_APP_APIURL}/site-data/`);
}

export const getPlans = async () => {
	const ret = await fetchData();
	return ret.data.data.pricingPlans;
}

export const getFeaturedPlans = async () => {
    const ret = await fetchData()
    return ret.data.data.featuredPlans;
}

export const getDemoDashboardCfg = async () => {
    const ret = await fetchData()
    return ret.data.data.demoDashboardCfg;
}