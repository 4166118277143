


export const  navData = [
	{key: 'dashboard', route:  '/dashboard', text: 'Dashboard'},
	{key: 'transactions', route:  '/transactions', text: 'Transactions'},
	{key: 'managePayments', route:  '/manage-payments', text: 'Manage Payments'},
	{key: 'profile', route:  '/profile', text: 'Profile'},
	{key: 'pricing', route:  '/pricing', text: 'Buy Notes'},

	{key: 'glossary', href:  'https://www.tripnotepros.com/glossary', text: 'Glossary'},
	{key: 'tutorials', href:  'https://streamyard.com/i299itgvcfh5yjqf', text: 'BENNY\'S TUTORIALS'},
	{key: 'contact', href:  'mailto:benny@tripnotepros.com', text: 'Contact Us'},

	{key: 'logout', route: '/login', text: 'Log Out'},
];
