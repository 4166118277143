//import * as React from 'react';
//className={classNames('nav-link w-nav-link', {'w--current': selectedTrack === item.track})}
//className={classNames('dropdown-toggle w-dropdown-toggle',      {'w--open': open})}

import React from 'react';
import classNames from 'classnames';
import { navData } from './navData';


const Item = ({ item, idx, clickHandler}) => {

  const handleClick = (e) => {
	clickHandler(e);
  };

  return (
    <>
      {item.href ? (
        <a key={idx}  className='dropdown-link w-dropdown-link' href={item.href}  target='_blank'>{item.text}</a>
      ) : (
        <a
          key={idx}
          className='dropdown-link w-dropdown-link'
          data-nav={item.key}
          onClick={handleClick}
        >
          {item.text}
        </a>
      )}
    </>
  );
};



const ProfileDropDown = ({user, propsParent}) => {

	const [open, setOpen] = React.useState(false);

/*
	const dropHandler = () => {
		setOpen(!open);
	}
*/

	const dropHandler = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const navHandler = function () {

		let props = null;

		return {
			onClick: (e) => {
				setOpen((prevOpen) => !prevOpen);

				const target = e.target.dataset.nav;
				const o = navData.find((element) => element.key === target);

				if (o && o.key === 'logout') {
					propsParent.logout();
				}

				if (o && o.route) {
					propsParent.history.push(o.route);
				}
			},

			init: (p) => {
				props = p;
			},
		};
	}();

	navHandler.init(propsParent);

    return (
	<div className='profile-block'>
		<div className='w-dropdown'>

			<div className='dropdown-toggle w-dropdown-toggle' onClick={dropHandler}>
				{user && user.photoURL && (
				<div className='profile-img'>
					<img src={user.photoURL} width='36' height='36' alt='' />
				</div>
				)}
				<div className='profile-arrow w-icon-dropdown-toggle'></div>
				<div className='profile-name'>{user.displayName}</div>
			</div>

			<nav className={classNames('dropdown-list w-dropdown-list', {'w--open': open})}>
				{navData.map((item,idx) => (
					<Item key={idx} item={item} idx={idx} clickHandler={navHandler.onClick} />
				))}
			</nav>
		</div>
	</div>
    );
};

export default ProfileDropDown;







