const grades = [
	 '',
	'STRONG DOWNGRADE',
	'DOWNGRADE',
	'MILD DOWNGRADE',
	'MILD UPGRADE',
	'UPGRADE',
	'STRONG UPGRADE',
    'EXCUSED'
];

export default grades;
