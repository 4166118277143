import Login from './pages/Membership/Login';
//import DashboardOld from './pages/Dashboard/Dashboard';

import DashboardV4 from './pages/DashboardV4/Dashboard';
import SampleDashboard from './pages/SampleDashboard/SampleDashboard';
import DemoDashboard from './pages/DemoDashboard/DemoDashboard';

import Signup          from './pages/Membership/Signup';
import ForgotPassword  from './pages/Membership/ForgotPassword';
import ResetPassword   from './pages/Membership/ResetPassword';
import Pricing         from './pages/Pricing/Pricing';
import PricingTrack    from './pages/Pricing/PricingTrack';
import Onboarding      from './pages/Onboarding/Onboarding';
import Home            from './pages/Home/Home';

import Transactions    from './pages/Transactions/Transactions';
import PaymentTypes    from './pages/PaymentTypes/PaymentTypes';
import Profile         from './pages/Profile/Profile';

//  vip@tripnotepros.com
// andrew707@gmail.com


/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    private: true
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    private: false
  },
  {
    path: '/signup',
    exact: true,
    component: Signup,
    private: false
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
    private: false
  },
  {
    path: '/auth-action',
    exact: true,
    component: ResetPassword,
    private: false
  },
  {
    path: '/dashboard',
    exact: true,
    component: DashboardV4,
    private: true
  },
  {
    path: '/sample-dashboard',
    exact: true,
    component: SampleDashboard,
    private: false
  },

  {
    path: '/demo',
    exact: true,
    component: DemoDashboard,
    private: false
  },
/*
  {
    path: '/dashboard-old',
    exact: true,
    component: DashboardOld,
    private: true
  },
*/
  {
    path: '/home',
    exact: true,
    component: Home,
    private: false
  },
  {
    path: '/pricing',
    exact: true,
    component: Pricing,
    private: false
  },
  {
    path: '/pricing-track',
    exact: true,
    component: PricingTrack,
    private: true
  },
  {
    path: '/onboarding',
    exact: true,
    component: Onboarding,
    private: false
  },
  {
    path: '/transactions',
    exact: true,
    component: Transactions,
    private: true
  },
  {
    path: '/manage-payments',
    exact: true,
    component: PaymentTypes,
    private: true
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
    private: true
  }
];

export default routes;
