import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../global/Helpers';
import { setOnboardingLoader, setOnboardingData } from '../actions/onboardingActions';

/**
 * @desc get onboarding Details
 */
export const getOnboardingDetails = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setOnboardingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/onboarding`);


    const { data } = response.data;
    if (data) {
      dispatch(setOnboardingData(data));
    } else {
      dispatch(setOnboardingData({}));
    }
    return data;
  } catch (e) {

    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch onboarding details', dispatch);

    return false;
  } finally {
    dispatch(setOnboardingLoader(false));
  }
};

/**
 * @desc set onboarding Details
 */
export const setOnboardingDetails = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchError('Atleast one user Type selection is Required.', dispatch);
      return;
    } else if (!obj.user_type || obj.user_type.length === 0) {
      dispatchError('Atleast one user Type selection is Required.', dispatch);
      return;
    } else if (!obj.interested_racetracks || obj.interested_racetracks.length === 0) {
      dispatchError('Atleast one race track selection is Required.', dispatch);
      return;
    }
    dispatch(setOnboardingLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/onboarding`, obj);
    const { data } = response.data;
    if (data) {
      dispatch(setOnboardingData(data));
    } else {
      dispatch(setOnboardingData({}));
    }
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to update onboarding details', dispatch);
    return false;
  } finally {
    dispatch(setOnboardingLoader(false));
  }
};

function dispatchError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
