import React from 'react';
import Dashboard from '../DashboardV4/Dashboard';
import { REACT_APP_APIURL } from '../../global/Environment';

import { getDemoDashboardCfg } from '../../services/siteDataService';


const getUserProfile = () => {
	return Promise.resolve({
		displayName: "Joe Horseplayer",
		firstName: "Joe",
		lastName: "Horseplayer",
		email: "andrew707@gmail.com",
		id: "MkKWSc9PFiX6Zel7XDtpKbCssHz2",
		language: "en"
	});
};


const NoteContent = () => {
    let btnStyle = {
        marginTop: '10px',
        borderColor: '#39d377', 
        color:  '#39d377'
        };

    let url = 'https://dashboard.tripnotepros.com/signup';

    const goToSignup = () => {
        window.location.href = url;
    }

    return (
        <>
       Go to  <a href={url}>tripnotepros.com</a> to receive FREE highlight notes daily 
       and purchase trip note analysis for today's entire card!
        <br />
        <button type="button" onClick={goToSignup} className="racing-tab" style={btnStyle}>Sign Up</button> 
        </>
    );
};
  
  
function replaceNotesForRaces(idxToLeaveAsIs, data) {
  let key = 0;
  data.data.forEach((entry) => {
    entry.dates[0].races.forEach((race, idx) => {
      if (idx !== idxToLeaveAsIs) {
        race.horses.forEach((horse) => {
          horse.notes.forEach((note) => {
            note.grade = 0;
            note.summary = '';
            note.reac_note = '';
            note.note =  <NoteContent key={++key} />;
          });
        });
      }
    });
  });
}

const getRaceIdxToHighlight = () => {
    const { races } = raceData[0].dates[0];
    const idx = races.findIndex(race => race.race_number === raceNumber);
    return idx !== -1 ? idx : 0;
}

var raceData;

const getRaceData = async () => {
    let data;
    let idxToLeaveAsIs = raceNumber -1;

	try {
        const res = await fetch(`${REACT_APP_APIURL}/race-v2/demo?lang=en`);
        if (res.ok)
             data = await res.json();
        
        replaceNotesForRaces(idxToLeaveAsIs, data);
       
        raceData = data.data;
        return data.data;
    } catch (e) {
        alert('oops...Error getting sample data');
    }
}

//------ start:

var raceNumber = await (async () => {
    const cfg = await getDemoDashboardCfg();
    return cfg.raceNumber;
})();

function DemoDashboard ({history}) {
    return (
    <>
      <Dashboard
         history={history}
         getUserProfileOverRide={getUserProfile}
         getRaceDataOverRide={getRaceData}
         getRaceIdxToHighlight={getRaceIdxToHighlight}
     />
    </>
  );
}

export default DemoDashboard;