// AW changed to add useState, useEffect
import React, { Component } from 'react';
//import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import images from '../../assets/images';
import { Elements } from '@stripe/react-stripe-js';
import { setErrorMessage, clearResponseMessage } from '../../actions/messageActions';
//import { initWebflowReady } from '../../global/Helpers';
import PaymentModal from './PaymentModal';
import { setSelectedBuyTracks, setSelectedPlan } from '../../actions/pricingActions';
import { chargePaymentOffSession, createPaymentIntent, getCustomerCardDetails } from '../../services/pricingService';
import { loadStripe } from '@stripe/stripe-js';
//import pricingPlans from '../../data/pricingPlans';
import { REACT_APP_STRIPE_PUBLIC_KEY } from '../../global/Environment';
import { trackActivity } from '../../services/analyticsService';
import { logout } from '../../services/authService';

import Loading from '../../components/Loading/Loading';
import ProfileDropDown from '../../components/ProfileDropDown/ProfileDropDown';
import FeaturedPlan from './FeaturedPlan';


// AW NEW data.pricingPlans replacment
//import  getPlans as getPricingPlans from '../../services/pricingPlans';

import { getPlans as getPricingPlans,  getFeaturedPlans} from '../../services/siteDataService';



const stripe = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

class Pricing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isPaymentModal: false
    };

    this.pricingPlans = null;
  }

  async componentDidMount() {
    //initWebflowReady();
    trackActivity('navigation: pricing');
    this.props.clearResponseMessage();

    // AW new... gets price from firestore

    //try {
    	const plans = await getPricingPlans();
    	/* const featuredPlan = await getFeaturedPlan(); */
        const featuredPlans = await getFeaturedPlans();
	//} catch (e) {

	//}

    let planIndv = plans.find((x) => x.id === 'individual_track');
    this.pricingPlans = plans;

    this.setState({
    	dailyCardPrice: planIndv.price,
    	/* featuredPlan: featuredPlan, */
        featuredPlans
    });
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  // when user clicks daily card
  onPickTrackClick =  (planId) => {
    const { user } = this.props.auth;
    if (user && user.email) {

      this.setSelectedPlan(planId);

      this.props.history.push(`/pricing-track?planId=${planId}`);
    } else {
      this.props.history.push('/signup');
    }
  };

  setSelectedPlan =  (planId) => {
    if (!planId) return;
	// AW new
     const selectedPlan = this.pricingPlans.find((x) => x.id === planId);

    this.props.setSelectedPlan(selectedPlan);
  };

  onParticularPlanClick = (planId) => {
    const { user } = this.props.auth;

    if (user && user.email) {
      this.setSelectedPlan(planId);
      this.onOpenPaymentModal();
    } else {
      this.props.history.push('/signup');
    }
  };

  onOpenPaymentModal = () => {
    this.props.clearResponseMessage();
    this.setState({ isPaymentModal: true });
  };

  onClosePaymentModal = () => {
    this.setState({ isPaymentModal: false });
  };



  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {

    const { user } = this.props.auth;
    const { loading, selectedPlan, selectedBuyTracks } = this.props.pricing;

    const { dailyCardPrice,
            featuredPlans,
    		isPaymentModal } = this.state;

    if (! dailyCardPrice)
      return <Loading />;
    else
    return (
      <div className='login-body'>
        <div className='navbar pricing w-nav'>
          <div className='container w-container'>
            <div className='nav-inner-home pricing'>
              <a className='brand-logo-home w-nav-brand'>
                <img src={images['logo'].default} alt='' />
              </a>

                <ProfileDropDown
                    user={user}
                    propsParent={this.props}
                />

              <div className='menu-button w-nav-button'>
                <div className='icon w-icon-nav-menu'></div>
                <div className='top-line'></div>
                <div className='middle-line'></div>
                <div className='bottom-line'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='pricing-content'>
          <div className='pricing-block'>
            <div className='pricing-box-wp'>
              <div className='pricing-box'>
                <h5 className='plan-text'>Daily Card</h5>
                <div className='pricing-text'>

                  <span className='doller-text'>$</span>
                  <p>{dailyCardPrice}</p>

                </div>
                <p className='plan-p'>per track per day</p>
                <button
                  type='button'
                  className='btn-square w-button'
                  onClick={() => {
                    this.onPickTrackClick('individual_track');
                  }}>
                  Get Started
                </button>
              </div>




            <FeaturedPlan
                plan={featuredPlans.featured1}
                pricingPlans={this.pricingPlans}
                handler={this.onParticularPlanClick}
            />


            <FeaturedPlan
                plan={featuredPlans.featured2}
                pricingPlans={this.pricingPlans}
                handler={this.onParticularPlanClick}
            />


            </div>
          </div>
        </div>
        {/* Modal */}

        {isPaymentModal && (
          <Elements stripe={stripe}>
            <PaymentModal
              selectedBuyTracks={selectedBuyTracks}
              selectedPlan={selectedPlan}
              amount={selectedPlan.price}
              loading={loading}
              {...this.props}
              onClosePaymentModal={this.onClosePaymentModal}
            />
          </Elements>
        )}
        {this.renderErrors()}
      </div>
    );
  }
}

Pricing.propTypes = {
  message: PropTypes.object.isRequired,
  pricing: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  pricing: state.pricing,
  auth: state.auth,
  onboarding: state.onboarding
});

export default connect(mapStateToProps, {
  setSelectedBuyTracks,
  setErrorMessage,
  setSelectedPlan,
  clearResponseMessage,
  createPaymentIntent,
  chargePaymentOffSession,
  getCustomerCardDetails,
  logout
})(Pricing);
