import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearResponseMessage } from '../../actions/messageActions';
import Loading from '../../components/Loading/Loading';
import ProfileDropDown from '../../components/ProfileDropDown/ProfileDropDown';
import images from '../../assets/images';
import { trackActivity } from '../../services/analyticsService';
//import { initWebflowReady } from '../../global/Helpers';
import { logout } from '../../services/authService';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
   
   debugger
    trackActivity('navigation: landing');
    this.LoadData();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  LoadData = async () => {
   // await this.props.getTransactionList();
   return;
  };


  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { user } = this.props.auth;
    //const { loading } = this.props.pricing;
    const loading = false

    if (loading) return <Loading />;
    return (
      <div className='login-body'>
        <div className='navbar pricing w-nav'>
          <div className='container w-container'>
            <div className='nav-inner-home pricing'>
              <a className='brand-logo-home w-nav-brand'>
                <img src={images['logo'].default} alt='' />
              </a>

                <ProfileDropDown
                    user={user}
                    propsParent={this.props}
                />

              <div className='menu-button w-nav-button'>
                <div className='icon w-icon-nav-menu'></div>
                <div className='top-line'></div>
                <div className='middle-line'></div>
                <div className='bottom-line'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='pricing-content normal'>
          <div className='container w-container'>
            <div className='transaction-wrapper'>
              <div className='pricing-head-block'>
                <h4 className='pricing-title'>Home</h4>
              </div>
              <div className='transaction-list-wp'>
                <div className='track-list'>



                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  message: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  logout
})(Home);
