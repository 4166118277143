import React from "react";


const FeaturedPlan = ({
        plan,
        pricingPlans,
        handler,
	}) => {

	// AW: Originally, there'd be only one featured plan displayed on the site,
	// so I had it so you'd add minimal info and the rest would come from
	// the pricing  plans list. Now, that the "we" want multiple featured plans to display,
	// it's a little cumbersome.

    const fillinFeaturedPlan = (featuredPlan, pricingPlans) => {
        if(featuredPlan && featuredPlan.isLive) {

           let thePlan = pricingPlans.find((x) => x.id === featuredPlan.id)

           featuredPlan.price = thePlan.price;
           featuredPlan.name  = featuredPlan.name || thePlan.name;
           featuredPlan.description = featuredPlan.description || thePlan.displayName;

           return featuredPlan;
        }
    }

    fillinFeaturedPlan (plan, pricingPlans);

	return(
        <>
        {plan && plan.isLive ? (

            <div className='pricing-box pro'>
            <h5 className='plan-text'>{plan.name}</h5>
            <div className='pricing-text'>
                <span className='doller-text'>$</span>{plan.price}
            </div>
            <p className='plan-p lg'>
                {plan.description}
                <br />
                <br />
            </p>
            <button
                type='button'
                className='btn-square w-button'
                onClick={() => {
                    handler(plan.id);
                }}>
                Get Started
            </button>
            </div>

        ) : (
            <></>
        )}
        </>
    );
};

export default FeaturedPlan;











