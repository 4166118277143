import React from "react";
import classNames from "classnames";


const RaceDateDropDowns = ({
		data,
		field,
		selectedItem,
		handler
	}) => {

	const [open, setOpen] = React.useState(false);

	const dropHandler = () => {
		setOpen(!open);
	}

	const itemHandler = (idx) => {
		handler(idx)
		setOpen(!open);
	}

	return(
	<div className='w-dropdown'>
		<div className='dropdown-toggle w-dropdown-toggle'  onClick={dropHandler}>
			<span>{selectedItem}</span>
			<div className='profile-arrow w-icon-dropdown-toggle'></div>
		</div>
		<nav className={classNames('dropdown-list w-dropdown-list', {'w--open': open})}>
			{!! data.length && data.map((item,idx) => (
				<a key={idx}
					className={classNames('nav-link w-nav-link', {
						'w--current': selectedItem === item[field]
					})}
					onClick={() => {
						itemHandler(idx);
					}}>

					{item[field]}
				</a>
			))}
		</nav>
	</div>
    );
};

export default RaceDateDropDowns;