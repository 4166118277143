import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearResponseMessage } from '../../actions/messageActions';
import { getTransactionList } from '../../services/pricingService';
import Loading from '../../components/Loading/Loading';
import ProfileDropDown from '../../components/ProfileDropDown/ProfileDropDown';
import images from '../../assets/images';
import { trackActivity } from '../../services/analyticsService';
//import { initWebflowReady } from '../../global/Helpers';
import { logout } from '../../services/authService';

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentModal: false
    };
  }

  componentDidMount() {
    trackActivity('navigation: transactions');
    this.LoadData();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  LoadData = async () => {
    await this.props.getTransactionList();
    //initWebflowReady();
  };


  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { user } = this.props.auth;
    const { loading, transactionList } = this.props.pricing;

    if (loading && !transactionList) return <Loading />;
    return (
      <div className='login-body'>
        <div className='navbar pricing w-nav'>
          <div className='container w-container'>
            <div className='nav-inner-home pricing'>
              <a className='brand-logo-home w-nav-brand'>
                <img src={images['logo'].default} alt='' />
              </a>

                <ProfileDropDown
                    user={user}
                    propsParent={this.props}
                />

              <div className='menu-button w-nav-button'>
                <div className='icon w-icon-nav-menu'></div>
                <div className='top-line'></div>
                <div className='middle-line'></div>
                <div className='bottom-line'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='pricing-content normal'>
          <div className='container w-container'>
            <div className='transaction-wrapper'>
              <div className='pricing-head-block'>
                <h4 className='pricing-title'>All Transaction</h4>
              </div>
              <div className='transaction-list-wp'>
                <div className='track-list'>
                  {transactionList &&
                    transactionList.length > 0 &&
                    transactionList.map((transaction, i) => {
                      return (
                        <div key={String(transaction.id + '_' + i)}>
                          {transaction.tracks &&
                            transaction.tracks.length > 0 &&
                            transaction.tracks.map((item, index) => {
                              const amount = transaction.amount / transaction.tracks.length;
                              const dateText = transaction.hasTracksWithToDates ? `${item.fromDate} to ${item.toDate}` : item.date;
                              return (
                                <div key={String(item.track + index)} className='track-item'>
                                  <div className='track-info trans'>
                                    <h5 className='track-title'>{item.track}</h5>
                                  </div>
                                  <div className='track-time'>
                                    <p className='race-text'>{dateText}</p>
                                  </div>
                                  <div className='track-btn trans'>
                                    <div className='transaction-amount'>${amount}</div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Transactions.propTypes = {
  message: PropTypes.object.isRequired,
  pricing: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.message,
  pricing: state.pricing,
  auth: state.auth
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  getTransactionList,
  logout
})(Transactions);
