import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError, isEmpty } from '../global/Helpers';
import { setPricingLoader, setPricingTrack, setTransactionList, setPaymentTypesList } from '../actions/pricingActions';

/**
 * @desc get pricing tracks
 */
export const getPricingTracks = (isAllTracks, planId, apiVersion) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setPricingLoader(true));

    let params = {};
    params = { isAllTracks: isAllTracks };

    let urlPiece = '';


    if(apiVersion=='v2')
        urlPiece  = `-${apiVersion}`
    else
       urlPiece = '';


	//let urlPiece = (apiVersion) ? `-${apiVersion}`:''

    let url = `${REACT_APP_APIURL}/race${urlPiece}/purchase-tracks`

    const response = await axios.get(url, { params: params });



    const { data } = response.data;

/*  AW Note:

	data [
		{date: "2024-05-20T00:00:00.000Z" formated_date: "Monday, May 20th" track: "PIMLICO"},
		{date: "2024-05-20T00:00:00.000Z" formated_date: "Monday, May 20th" track: "DELMAR"},
	]
*/


    let list = data;

/*   AW 2023-05-22 individual tracks (daily card) ALWAYS are planId === 'individual_track'
    if (planId === 'individual_track') {
      list = data.filter((x) => x.track !== '----PIMLICO');
    } else if (planId === 'pimlico_track') {
      list = data.filter((x) => x.track === 'PIMLICO');
    } else if (planId === 'particular_track_DMR') {
      list = data.filter((x) => x.track === 'DEL MAR');
    } else if (planId === 'particular_track_SRT') {
      list = data.filter((x) => x.track === 'SARATOGA');
    }
*/

    dispatch(setPricingTrack(list));

/*  AW Note:
	export const setPricingTrack = (payload) => {
		return {
			type: actions.SET_PRICING_TRACK,
			payload: payload
		};
	};

*/

    return list;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch pricing tracks', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

/**
 * @desc charge payment
 */
export const chargePayment = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchError('Card Details is required', dispatch);
      return;
    } else if (!obj.plan) {
      dispatchError('Selected Plan is Required', dispatch);
      return;
    } else if (!obj.token) {
      dispatchError('Payment Token is Required', dispatch);
      return;
    } else if (!obj.tracks) {
      dispatchError('Tracks are Required', dispatch);
      return;
    }
    dispatch(setPricingLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/payment/charge`, obj);
    const { data } = response;
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to proceed payment, Pease try again later', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

/**
 * @desc get transactions
 */
export const getTransactionList = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setPricingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/payment/transactions`);
    let data = response.data.data;
    if (data && data.length > 0) {
      data.forEach((x) => {
        x.tracks = x.tracks.filter((y) => !!y.track);
      });
      data = data
        .filter((x) => x.tracks.length > 0)
        .sort((a, b) => {
          const date1 = new Date(a.date);
          const date2 = new Date(b.date);
          if (date1 > date2) return 1;
          if (date2 > date1) return -1;

          return 0;
        });
      dispatch(setTransactionList(data));
    } else {
      dispatch(setTransactionList([]));
    }
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch transactions', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

export const getCustomerCardDetails = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setPricingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/payment/card-details`);
    const { data } = response.data;
    if (data) {
      dispatch(setPaymentTypesList(data));
    } else {
      dispatch(setPaymentTypesList([]));
    }
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch card details', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

export const updateCustomerPaymentMethod = (id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!id) {
      dispatchError('Payment Type id to be updated is not found', dispatch);
      return;
    } else if (!obj || isEmpty(obj.expMonth)) {
      dispatchError('Exp month could not be empty', dispatch);
      return;
    } else if (isEmpty(obj.expYear)) {
      dispatchError('exp year could not be empty', dispatch);
      return;
    }
    dispatch(setPricingLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/payment/payment-type/${id}`, obj);
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to delete payment type', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

export const deleteCustomerPaymentMethod = (id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!id) {
      dispatchError('Payment Type id to be deleetd is not found', dispatch);
      return;
    }
    dispatch(setPricingLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/payment/payment-type/${id}`);
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to delete payment type', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

export const createPaymentIntent = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setPricingLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/payment/create-payment-intent`, obj);
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to create payment intent', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

export const chargePaymentOffSession = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setPricingLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/payment/charge-payment-off-session`, obj);
    const { data } = response.data;
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to create payment intent', dispatch);
    return false;
  } finally {
    dispatch(setPricingLoader(false));
  }
};

function dispatchError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
