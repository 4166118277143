import { combineReducers } from 'redux';
import messageReducer from './messageReducer';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import pricingReducer from './pricingReducer';
import onboardingReducer from './onboardingReducer';
//import sampledashboardReducer from './sampledashboardReducer';

export default combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  message: messageReducer,
  dashboard: dashboardReducer,
  pricing: pricingReducer,
});
