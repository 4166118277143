import React from "react";
import classNames from 'classnames';
import gradeLabels from "../../data/grades";


const Notes = ({
		 notes,
		 selectedNote,
		 onNotesClick
	 }) => {

	const [open, setOpen] = React.useState(false);

	if(!notes || !selectedNote)
		return null;

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleClick = (idx) => {
		setOpen(!open);
		onNotesClick(idx);
	};

	const getSurfaceColor = (type) => {
		if (!type) return;
		const name = type.toLowerCase().trim();
		if (name === 'dirt') return '';
		else if (name === 'turf') return 'green';
		else if (name === 'synth' || name === 'synthetic') return 'blue';
	};

	return (
	<div className='rightbar-content'>
		<div className='trip-info-block'>
			<div className='trip-title-block'>

				<div className='tp-dropdown'>
					<div className='dropdown-toggle tp-dropdown-toggle'
						onClick={() => {
								handleOpen();
							}
					}>

						<h4 className='trip-title'>Trip Date</h4>
						<div className='date-text'><span className='date-text sm'>{selectedNote.date}</span></div>
						<div className='profile-arrow tp-icon-dropdown-toggle'></div>
					</div>

					{open ? (
					<nav className={classNames('dropdown-list tp-dropdown-list')}>

						{  !! notes.length && notes.map((note,idx) => (

							<a key={idx}
								className={classNames('nav-link tp-nav-link', {
									'w--current': selectedNote.id === note.id
									})
								}
								onClick={() => {
									{ handleClick(idx);  }
								}}>
								{`${note.date}  ${note.track}`}
							</a>

						))}
					</nav>
					) : null}
				</div>
			</div>

			<div className='distance-wp'>
				<div className='dis-surface-block left'>
					<h4 className='trip-sub-title'>Distance</h4>
					<div className='date-text'>
						<span className='trip-para'>{selectedNote?.distance || 'N/A'}</span>
					</div>
				</div>


				<div className='dis-surface-block'>
					<h4 className='trip-sub-title'>Surface</h4>
					<div className='surface-type-block'>

						{selectedNote?.surface ? (
						<div
							className={classNames('surface-type', `${getSurfaceColor(selectedNote?.surface)}`)}>
							{selectedNote?.surface}
						</div>
						) : (
						<p className='date-text trip-para'>{'N/A'}</p>
						)}
					</div>
				</div>
			</div>


		</div>

		<div className='trip-info-block'>
			<div className='trip-title-block'>
				<div className='border-blk'></div>
				<h4 className='trip-title'>TRIP NOTE</h4>
			</div>
			<p className='trip-para'>{selectedNote?.note || 'N/A'}
            <br />
            {(selectedNote?.grade) ? gradeLabels[selectedNote?.grade] : ''}
            </p>

		</div>

		<div className='trip-info-block'>
			<div className='trip-title-block'>
				<div className='border-blk'></div>
				<h4 className='trip-title'>RACE NOTE</h4>
			</div>
			<p className='trip-para'>{selectedNote?.race_note || 'N/A'}</p>
		</div>

		<div className='trip-info-block'>
			<div className='trip-title-block'>
				<div className='border-blk'></div>
				<h4 className='trip-title'>TRACK BIAS</h4>
			</div>
			<p className='trip-para'>{false || 'N/A'}</p>
		</div>

		<div className='trip-info-block'>
			<div className='trip-title-block'>
				<div className='border-blk'></div>
				<h4 className='trip-title'>SUMMARY</h4>
			</div>
			<p className='trip-para'>{selectedNote?.summary || 'N/A'}</p>
		</div>

	</div>

  );
};

export default Notes;