import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { isEmpty, getAPIResponseError, isEmail, getFirstAndLastName } from '../global/Helpers';
import { setAuthLoader, setCurrentUser, setUserProfile } from '../actions/authActions';
import { REACT_APP_APIURL } from '../global/Environment';
import localdataService from './localdataService';
import { setAuthToken, clearAuthToken } from '../utils/authTokenHelpers';
import { setUser, clearUser, trackActivity } from './analyticsService';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyASbSdDDEJR4vFed4VZksypNWNwTb2N8xs',
  authDomain: 'trip-note-pro.firebaseapp.com',
  databaseURL: 'https://trip-note-pro.firebaseio.com',
  projectId: 'trip-note-pro',
  storageBucket: 'trip-note-pro.appspot.com',
  messagingSenderId: '996173340722',
  appId: '1:996173340722:web:342c90aaf88cfeb56d9c2b',
  measurementId: 'G-J4D1759HHS'
};
// Initialize Firebase
if (window.firebase) window.firebase.initializeApp(firebaseConfig);
/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const login = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Email address is Required', dispatch);
      return;
    } else if (!obj.username) {
      dispatchAuthError('Email address is Required', dispatch);
      return;
    } else if (isEmail(obj.username) === false) {
      dispatchAuthError('Please enter a valid email address', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    }

    dispatch(setAuthLoader(true));


    const response = await axios.post(`${REACT_APP_APIURL}/auth/login`, obj);
    const { data } = response.data;

    dispatch(setAuthLoader(false));
    dispatch(setLoginToken(data));

    return true;

  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(
      getAPIResponseError(e, dispatch) || "Can't sign in, please verify your login information",
      dispatch
    );
    return false;
  }
};

export const socialLogin = (dataProvider) => async (dispatch) => {
  var provider;
  if (!window.firebase) return;

  try {
    dispatch(setAuthLoader(true));
    if (dataProvider === 'google.com') provider = new window.firebase.auth.GoogleAuthProvider();
    else if (dataProvider === 'facebook.com') provider = new window.firebase.auth.FacebookAuthProvider();
    else if (dataProvider === 'twitter.com') provider = new window.firebase.auth.TwitterAuthProvider();
    const resultPromise = new Promise(function (resolve, reject) {
      window.firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          var user = result.user;
          const { xa, displayName, email, uid, refreshToken } = user;
          const tokenItem = {
            access_token: xa,
            displayName: displayName,
            email: email,
            expiresIn: 3600,
            id: uid,
            refresh_token: refreshToken
          };
          dispatch(setLoginToken(tokenItem));
          resolve(tokenItem);
        })
        .catch(function (error) {
          var errorMessage = error.message;
          console.log(error);
          reject(errorMessage);
        });
    });
    const result = await resultPromise;
    return result;
  } catch (e) {
    dispatchAuthError(
      getAPIResponseError(e, dispatch) || "Can't sign in, please verify your login information",
      dispatch
    );
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Signup - signup user and set login information after successfuly signup
 * @param {*} obj Data Obj
 */
export const signup = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('First Name is Required', dispatch);
      return;
    } else if (!obj.firstname || isEmpty(obj.firstname)) {
      dispatchAuthError('First Name is Required', dispatch);
      return;
    } else if (!obj.lastname || isEmpty(obj.lastname)) {
      dispatchAuthError('Last Name is Required', dispatch);
      return;
    } else if (!obj.email) {
      dispatchAuthError('Email address is Required', dispatch);
      return;
    } else if (isEmail(obj.email) === false) {
      dispatchAuthError('Please enter a valid email address', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    } else if (!obj.confirmPassword) {
      dispatchAuthError('Confirm password is Required', dispatch);
      return;
    } else if (obj.password !== obj.confirmPassword) {
      dispatchAuthError('Password not matched', dispatch);
      return;
    }

    dispatch(setAuthLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/auth/signup`, obj);
    const { data } = response.data;
    dispatch(setAuthLoader(false));
    dispatch(setLoginToken(data, true, obj.firstname, obj.lastname));
    return true;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(getAPIResponseError(e, dispatch) || 'Unable to signup, please try again', dispatch);
    return false;
  }
};

/**
 * @desc set login token and set user
 * @param {*} data Data obj
 * @param {*} isSaveInLocal flag for saving data in local storage
 */
export const setLoginToken = (data, isSaveInLocal = true, firstname = undefined, lastname = undefined) => (
  dispatch
) => {
  // save auth deteils and set token in header for request
  setAuthToken(data.access_token);
  //save user in localstorage
  if (isSaveInLocal) localdataService.setCurrentUser(data);
  // Decode token to get user data
  let user = {
    ...data,
    access_token: undefined
  };
  delete user['access_token'];
  // Set current user in redux
  dispatch(setCurrentUser(user));

  // Set Analytics
  if (!firstname && !lastname) {
    // if firstname and lastname are not defined. split them from user display name
    const nameObj = getFirstAndLastName(user.displayName);
    if (nameObj) {
      firstname = nameObj.firstname || '';
      lastname = nameObj.lastname || '';
    }
  }
  user = {
    email: user.email,
    name: user.displayName,
    firstname: firstname,
    lastname: lastname
  };
  setUser(user);
  // setLogUser(user);
};

/**
 * @desc Log user out
 */
export const logout = () => (dispatch) => {
  /**
   * Remove token from localStorage
   * Remove auth header for future requests
   * Set current user to {} which will set isAuthenticated to false
   */
  clearAuthToken();
  localdataService.ClearAllKeys();
  dispatch(setCurrentUser({}));
  // for Analytics
  trackActivity('signed out');
  clearUser();
};

/**
 * @desc  Forgot Password email
 * @param {*} obj user obj
 */
export const forgotPassword = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Registered email address is required', dispatch);
      return;
    } else if (!obj.email) {
      dispatchAuthError('Registered email address is required', dispatch);
      return;
    } else if (isEmail(obj.email) === false) {
      dispatchAuthError('Please enter a valid email address', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/auth/forgot-password`, obj);
    const { data } = response;
    dispatch(setAuthLoader(false));
    dispatch(setSuccessMessage(data.message));
    return true;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(getAPIResponseError(e, dispatch) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  Reset Password email
 * @param {*} obj user obj
 */
export const resetPassword = (obj) => async (dispatch) => {
  if (!window.firebase) return;
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('New Password is required', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('New Password is required', dispatch);
      return;
    } else if (!obj.confirmPassword) {
      dispatchAuthError('Confirm Password is required', dispatch);
      return;
    } else if (obj.password !== obj.confirmPassword) {
      dispatchAuthError('New Password and Confirm Password must be same', dispatch);
      return;
    } else if (!obj.token) {
      dispatchAuthError('Token is required', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));

    const resultPromise = new Promise(function (resolve, reject) {
      window.firebase
        .auth()
        .confirmPasswordReset(obj.token, obj.password)
        .then(function (result) {
          dispatch(setSuccessMessage('Password updated successfully, Please retry login with the updated password.'));
          resolve(true);
        })
        .catch(function (error) {
          var errorMessage = error.message;
          console.log('ERROR: ', error);
          reject(errorMessage);
        });
    });

    const result = await resultPromise;
    return result;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(e || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  get User Profile
 */
export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setAuthLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/user/profile`);
    const { data } = response.data;
    if (data) {
      dispatch(setUserProfile(data));
    } else {
      dispatch(setUserProfile({}));
    }
    return data;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(e || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  update User Profile
 * @param {*} obj user obj
 */
export const updateUserProfile = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!obj) {
      dispatchAuthError('Fields to be updated is required', dispatch);
      return;
    } else if (obj.firstName && isEmpty(obj.firstName)) {
      dispatchAuthError(`FirstName can't be empty`, dispatch);
      return;
    } else if (obj.lastName && isEmpty(obj.lastName)) {
      dispatchAuthError(`LastName can't be empty`, dispatch);
      return;
    } else if (obj.language && isEmpty(obj.language)) {
      dispatchAuthError(`Language can't be empty`, dispatch);
      return;
    }
    dispatch(setAuthLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/user/profile`, obj);
    const { data } = response.data;
    if (data) {
      dispatch(setUserProfile(data));
    } else {
      dispatch(setUserProfile({}));
    }
    return data;
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError(e || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

function dispatchAuthError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
