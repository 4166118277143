import * as actions from "./types";
import {SET_SADDLES} from "./types";

/**
 * @desc Set dashboard loader
 */
export const setDashboardLoader = (payload) => {
    return {
        type: actions.SET_DASHBOARD_LOADER,
        payload: payload
    };
};

/**
 * @desc Set race data
 */
export const setRaceData = (payload) => {
    return {
        type: actions.SET_RACE_DATA,
        payload: payload
    };
};

/**
 * @desc Set saddle data
 */
export const setSaddles = (payload) => {
    return {
        type: actions.SET_SADDLES,
        payload: payload
    };
};

/**
 * @desc Set selected race
 */
export const setSelectedRace = (payload) => {
    return {
        type: actions.SET_SELECTED_RACE,
        payload: payload
    };
};

/**
 * @desc Set selected race horses
 */
export const setSelectedRaceHorses = (payload) => {
    return {
        type: actions.SET_SELECTED_RACE_HORSES,
        payload: payload
    };
};