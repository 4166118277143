import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuthLayout from './AuthLayout';
import { clearResponseMessage } from '../../actions/messageActions';
import { login, socialLogin } from '../../services/authService';
import classNames from 'classnames';
import { getOnboardingDetails } from '../../services/onboardingService';
import { trackActivity, setNewUser } from '../../services/analyticsService';
import { getFirstAndLastName } from '../../global/Helpers';

import images from '../../assets/images';


//import {ADMIN_EMAIL, ADMIN_UI_URL} from "../../global/constant";
//import {REACT_ADMIN_UI_URL} from "../../global/Environment";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isRemeberMe: false,
      isShowPassword: false
    };
  }

  componentDidMount() {
    trackActivity('navigation: login');
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleEmailInputChange = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };

  handlePasswordInputChange = (e) => {
    const password = e.target.value;
    this.setState({ password });
  };

  handleRememberMeCheckboxChange = (e) => {
    const isRemeberMe = e.target.checked;
    this.setState({ isRemeberMe });
  };

  toggleIsShowPassword = () => {
    let isShowPassword = this.state.isShowPassword;
    isShowPassword = !isShowPassword;
    this.setState({ isShowPassword });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email, password, isRemeberMe } = this.state;
    const loginObj = {
      username: email,
      password: password,
      remember: isRemeberMe
    };

    const result = await this.props.login(loginObj);
    if (result === true) {

      this.trackSignInAnalytics(email, 'email');

		try {
			var onboardingData = await this.props.getOnboardingDetails();
		} catch (error) {

		}


      this.setUserProperties(onboardingData);


      // it goes from here to dashboard and no race data, from dashboard to pricing page
      if (onboardingData && onboardingData.user_type) {
        this.props.history.push('/dashboard');
      } else {
        this.props.history.push('/onboarding');
      }
    }
  };

  onClickForgotPassword = () => {
    this.props.history.push('/forgot-password');
  };

  onSignupClick = () => {
    this.props.history.push('/signup');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <div className='success-message'>{resSuccess}</div>;
    }
  }

  onSocialLoginClick = async (provider) => {
    const result = await this.props.socialLogin(provider);
    if (result) {
      this.trackSignInAnalytics(result.email, provider);
      const onboardingData = await this.props.getOnboardingDetails();
      this.setUserProperties(onboardingData);
      if (onboardingData && onboardingData.user_type) {
        this.props.history.push('/dashboard');
      } else {
        this.props.history.push('/onboarding');
      }
    }
  };

  trackSignInAnalytics = (email, type) => {
    let properties;
    if (email) properties = { email: email };
    if (type) properties = { ...properties, type: type }
    trackActivity('signed in', properties);
  }

  setUserProperties = (onboardingData) => {
    if (!onboardingData || !onboardingData.user_type) return;
    // set user properties
    const { user } = this.props.auth;
    if (!user) return;
    const nameObj = getFirstAndLastName(user.displayName);
    const { firstname, lastname } = nameObj || {};
    const analyticsObj = {
      name: user.displayName,
      email: user.email,
      firstname: firstname || '',
      lastname: lastname || '',
      track_preferences: onboardingData.interested_racetracks.join(', '),
      persona: onboardingData.user_type.join(', ')
    };
    setNewUser(analyticsObj);
  }

  render() {
    const loadingAuth = this.props.auth.loading;
    const loadingOnboarding = this.props.onboarding.loading;
    const loading_Login = loadingAuth || loadingOnboarding;
    const { email, password, isRemeberMe, isShowPassword } = this.state;
    return (
      <>
        <AuthLayout>
          <div className='rightbar-inner'>
            <div className='login-rightbar-head'>
              <a className='w-inline-block'>
                {/*<img src={require('../../assets/images/triplogo.png')} alt='' /> */}
                <img src={images['logo'].default} alt='' />
              </a>
            </div>
            <div className='login-rightbar-content'>
              <div className='signup-wrapper'>
                <h3 className='signup-text'>Sign In</h3>
                <div className='form-block'>
                  <form name='wf-form-Signup-Form' onSubmit={this.onSubmit}>
                    <div className='form-row'>
                      <div className='form-col'>
                        <label htmlFor='Email-9' className='form-lable'>
                          Email
                        </label>
                        <div className='input-block'>
                          <input
                            type='email'
                            className='form-input w-input'
                            maxLength='256'
                            name='Email'
                            placeholder='venkat@tradeanalyst.com'
                            required=''
                            value={email}
                            onChange={this.handleEmailInputChange}
                          />
                          <div className='user-icon'></div>
                        </div>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col'>
                        <label htmlFor='name-3' className='form-lable'>
                          Password
                        </label>
                        <div className='input-block'>
                          <input
                            type={isShowPassword ? 'text' : 'password'}
                            maxLength='256'
                            name='Password'
                            required=''
                            className='form-input w-input'
                            value={password}
                            onChange={this.handlePasswordInputChange}
                          />
                          <div
                            className={classNames('eye-icon', {
                              off: isShowPassword
                            })}
                            onClick={this.toggleIsShowPassword}></div>
                        </div>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col'>
                        <div className='forgot-block'>
                          <div className='w-embed'>
                            <label className='checkbox-field'>
                              Remember me
                              <input
                                type='checkbox'
                                name='checkbox'
                                checked={isRemeberMe}
                                onChange={this.handleRememberMeCheckboxChange}
                              />
                              <span className='checkbox-span-rem'></span>
                            </label>
                          </div>
                          <a className='forgot-link' disabled={loading_Login} onClick={this.onClickForgotPassword}>
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                    </div>
                    {this.renderErrors()}
                    {this.renderSuccess()}
                    <div
                      className={classNames('form-btn-block', {
                        'mc-loading': loading_Login === true
                      })}>
                      <button
                        type='submit'
                        data-wait='Please wait...'
                        className='btn-square w-button'
                        disabled={loading_Login}>
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='social-signup-wrapper'>
                <p className='social-signup-text'>Or continue with Social Sign On</p>
                <a
                  className='social-link-block social-login w-inline-block'
                  onClick={() => {
                    this.onSocialLoginClick('google.com');
                  }}>
                  <img src={require('../../assets/images/googleicon.svg')} alt='' />
                  <p className='social-text'>Continue with Google</p>
                </a>
                {/* <a className="social-link-block social-login w-inline-block">
                  <img
                    src={require("../../assets/images/facebookicon.svg")}
                    alt=""
                  />
                  <p className="social-text">Continue with Facebook</p>
                </a>
                <a className="social-link-block social-login w-inline-block">
                  <img
                    src={require("../../assets/images/twittericon.svg")}
                    alt=""
                  />
                  <p className="social-text">Continue with Twitter</p>
                </a> */}
              </div>
              <div className='login-rightbar-bottom'>
                <p className='rightbar-btm-text'>
                  Don’t have an account?{' '}
                  <a className='btm-link' disabled={loading_Login} onClick={this.onSignupClick}>
                    Register Here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </AuthLayout>
      </>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  onboarding: state.onboarding,
  message: state.message
});

export default connect(mapStateToProps, {
  login,
  clearResponseMessage,
  socialLogin,
  getOnboardingDetails
})(Login);
