export const API_URL         = 'https://us-central1-trip-note-pro.cloudfunctions.net/api';
export const API_URL_STAGING = 'https://us-central1-trip-note-pros-staging.cloudfunctions.net/api';

export const API_URL_DEVELOPMENT = 'http://localhost:5002/trip-note-pro/us-central1/api';

// or 127.0.0.1:
// export const API_URL_DEVELOPMENT = 'http://127.0.0.1:5002/trip-note-pro/us-central1/api';

//  or go direct to production cloud function:
//export const API_URL_DEVELOPMENT = 'https://us-central1-trip-note-pro.cloudfunctions.net/api';

// this is used. it's a hold over from when both the old spreadsheet backed api and the new mysql api were running at same time
// it's not really an api version but just tacked on to some api urls:
//    http://localhost:5002/trip-note-pro/us-central1/api/race-v2/v3?lang=en
//    `${REACT_APP_APIURL}/race${urlPiece}/purchase-tracks`
export const API_VERSION = 'v2';



export const STRIPE_PUBLIC_KEY = 'pk_live_gxhDOHxb18nFWUK50xH1geCh006SaD4v3f';
export const STRIPE_PUBLIC_KEY_DEVELOPMENT = 'pk_test_51GtLFqE8kmEFubKtiZUYyD9jBewPnfoXKkVgR4CsMQDAiHKxY1is4NN7UaxivzSj41nZQH3DxWxrAuAezi2IAOlW00waHB9nm9';
export const SEGMENT_KEY = 'SWdI8UrDO2Edunj5pgN4oDIVZpoktTpj';
export const ADMIN_EMAIL = 'andrew707@gmail.com';
