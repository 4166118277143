import * as actions from "../../actions/types";

const initialState = {
    raceData: {},
    selectedRace: {},
    selectedRaceHorses: [],
    saddles: {},
    loading: false,
};


/*
export const setDashboardLoader = (payload) => {
    return {
        type: actions.SET_DASHBOARD_LOADER,
        payload: payload
    };
};

*/
export default function (state = initialState, action) {

    switch (action.type) {
        case actions.SET_DASHBOARD_LOADER:
            return {
                ...state,
                loading: action.payload
            };
        case actions.SET_RACE_DATA:
            //const sortedTripListRaceData = getSortedTripList(action.payload);
            return {
                ...state,
                raceData: action.payload
            };
        case actions.SET_SADDLES:
            return {
                ...state,
                saddles: action.payload
            };
        case actions.SET_SELECTED_RACE:
            return {
                ...state,
                selectedRace: action.payload
            };
        case actions.SET_SELECTED_RACE_HORSES:
            return {
                ...state,
                selectedRaceHorses: action.payload
            };
        default:
            return state;
    }
}

const getSortedTripList = (data) => {
    if (!data) return;

    if (data.trips) {
        data.trips = data.trips
            .map((item) => {
                return { ...item, number: Number(item.horse_number) };
            })
            .sort((a, b) => {
                var o1 = a.race.toLowerCase();
                var o2 = b.race.toLowerCase();

                var p1 = a.number;
                var p2 = b.number;

                if (o1 < o2) return -1;
                if (o1 > o2) return 1;
                if (p1 < p2) return -1;
                if (p1 > p2) return 1;
                return 0;
            });
    }
    return data;
}
