import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { setDashboardLoader, setRaceData } from '../actions/dashboardActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../global/Helpers';



/**
 * @desc get race data
 */

// ths somehow sets bearer token
export const getRaceData = (language='en', v = '3') => async (dispatch) => {
    try {

        dispatch(clearResponseMessage());
        dispatch(setDashboardLoader(true));

        const response = await axios.get(`${REACT_APP_APIURL}/race-v2/v3?lang=${language}`);
        const { data } = response.data;

        dispatch(setRaceData(data));
        return data;

    } catch (e) {

        dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch race data', dispatch);
        return false;
    } finally {
        dispatch(setDashboardLoader(false));
    }
};

// how to set bearer token?
export const getRaceDataNEW = async (language='en') => {

    //return fetch(`${REACT_APP_APIURL}/race-v2/v3?lang=${language}`);
    return axios.get(`${REACT_APP_APIURL}/race-v2/v3?lang=${language}`)
    //const response = await axios.get(`${REACT_APP_APIURL}/race-v2/v3?lang=${language}`);
    //const { data } = response.data;
    //return data;
};



function dispatchError(msg, dispatch) {
    dispatch(setErrorMessage(msg));
}

