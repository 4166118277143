import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { trackActivity, setNewUser } from '../../services/analyticsService';
import { getFirstAndLastName } from '../../global/Helpers';

const PaymentModal = (props) => {

  // AW: props.selectedPlan is right from data/pricingPlans.js

  const stripe = useStripe();
  const elements = useElements();

  const [cardDetails, setCardState] = useState({
    savedCards: undefined,
    isSavingCard: false,
    selectedCard: undefined,
    isUseNewCard: false,
    loading: false,
  });

  const loadData = async () => {
    //get the card details for the customer
    const { getCustomerCardDetails } = props;
    setCardState({ ...cardDetails, loading: true });
    const result = await getCustomerCardDetails();
    setCardState({ ...cardDetails, savedCards: result, loading: false });
  };

/*
  const getSelectedTrackData = (track) => {
    if (!track) return;

    // wtf? AW
    const fromDate = new Date('7/14/2022');
    const toDate = new Date('9/11/2022');
    let tracks = [{ track: track, fromDate: fromDate, toDate: toDate }];
    return tracks;
  };
*/


  const tracksSelector = (selectedPlan, fromDate, toDate) => {

	let tracks = [];

	if(selectedPlan.type === 4) {

		tracks = [{track: selectedPlan.trackCode, fromDate: fromDate, toDate: toDate}];

	} else  if(selectedPlan.type === 10) {

		tracks = selectedPlan.tracks.map(track => ({track: track, fromDate: fromDate, toDate: toDate}));
	 }

	return tracks;
  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    trackActivity('payment: pay clicked', { amount: props.amount });
    let payload;
    if (!cardDetails.loading && (!cardDetails.savedCards || cardDetails.isUseNewCard)) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      //create payment method for the first time for a new card
      const card = elements.getElement(CardElement);
      payload = await stripe.createPaymentMethod("card", card);
    }
    onPaymentSubmit(payload);
  };

  const onPaymentSubmit = async (result) => {
    const { setErrorMessage } = props;
    if (result) {
      if (result.error) {
        // Inform the customer that there was an error.
        setErrorMessage(result.error.message);
      } else if (result.paymentMethod) {
        // charge first payment
        await chargePayment(result.paymentMethod.id);
      }
    } else {
      // charge the card after saved
      await chargePaymentNextTime();
    }
  };

  const chargePayment = async (paymentMethodId) => {

    const { selectedBuyTracks, selectedPlan, createPaymentIntent } = props;
    let tracks = selectedBuyTracks;

	let fromDate = new Date(selectedPlan.fromDate);
	let toDate = new Date(selectedPlan.toDate);

	if(selectedPlan.type > 2) {
		tracks = tracksSelector (selectedPlan, fromDate, toDate);
	}


    // Send the paymentMethod to your server.
    const paymentObj = {
      plan: selectedPlan.id,
      paymentMethod: paymentMethodId,
      tracks: tracks,
      planDesc: selectedPlan.name,
      amount:
        selectedPlan.type === 1 || selectedPlan.type === 2
          ? selectedPlan.price * selectedBuyTracks.length
          : selectedPlan.price,
      isSavingCard: cardDetails.isSavingCard
    };
    //create payment intent for saving card
    const paymentIntent = await createPaymentIntent(paymentObj);
    if (paymentIntent) {
      //handle confirmation action
      if (paymentIntent.status === 'requires_confirmation') {
        handleAction(paymentIntent.clientSecret);
      } else if (paymentIntent.status === 'succeeded') {
        trackPaymentSuccessful();
        onCloseModal();
        navToDashboard();
      }
    }
  };

  const handleAction = async (clientSecret) => {
    const { createPaymentIntent, setErrorMessage } = props;
    const result = await stripe.handleCardAction(clientSecret);
    if (result.error) {
      // Inform the customer that there was an error.
      setErrorMessage(result.error.message);
    } else if (result.paymentIntent.status === 'requires_confirmation') {
      // confirm the payment intent if required
      const intent = await createPaymentIntent({ paymentIntentId: result.paymentIntent.id });
      if (intent && intent.status === 'succeeded') {
        trackPaymentSuccessful();
        onCloseModal();
        navToDashboard();
      }
    }
  };

  const chargePaymentNextTime = async () => {
    const { selectedBuyTracks, selectedPlan, chargePaymentOffSession } = props;
    if (!selectedPlan) return;

	let tracks = selectedBuyTracks;

 	let fromDate = new Date(selectedPlan.fromDate);
	let toDate = new Date(selectedPlan.toDate);

	if(selectedPlan.type > 2) {
		tracks = tracksSelector (selectedPlan, fromDate, toDate);
	}

    const paymentObj = {
      plan: selectedPlan.id,
      tracks: tracks,
      planDesc: selectedPlan.name,
      amount:
        selectedPlan.type === 1 || selectedPlan.type === 2
          ? selectedPlan.price * selectedBuyTracks.length
          : selectedPlan.price,
      paymentMethod: cardDetails.selectedCard.paymentMethod
    };
    // charge payment off session from the card
    const result = await chargePaymentOffSession(paymentObj);
    if (result && result.status === 'succeeded') {
      trackPaymentSuccessful();
      onCloseModal();
      navToDashboard();
    }
  };

  const trackPaymentSuccessful = () => {
    const { selectedBuyTracks, amount, selectedPlan } = props;
    let trackNames = [];
    // add amount to event properties;
    let properties = { amount: amount };

    // add plan to event properties;
    if (selectedPlan.displayName) {
    	properties = { ...properties, 'plan_name': selectedPlan.displayName };
    }

    // add track Names based on selected plan to event properties;
    // this track plan code is shittiest shit code ever
    if (selectedPlan.type === 3 || selectedPlan.type === 4) {
       // AW Note: type has nothing to do with track names !!!
        if (selectedPlan.type === 3) {
      	trackNames.push('DEL MAR');
      } else if (selectedPlan.type === 4) {
      	trackNames.push('SARATOGA');
      }
    } else if (selectedBuyTracks && selectedBuyTracks.length > 0) {
      trackNames = selectedBuyTracks.map((item) => {
        return String(item.track).toLowerCase();
      })
    }
    if (trackNames && trackNames.length > 0) {
      //get unique track names only
      trackNames = trackNames.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      properties = { ...properties, "track_name": trackNames };
    }

    trackActivity('payment: successful', properties);
    setUserProperties();
  }

  const setUserProperties = () => {
    const { user } = props.auth;
    if (!user) return;
    const { onboardingData } = props.onboarding;
    if (!onboardingData || !onboardingData.user_type) return;

    const nameObj = getFirstAndLastName(user.displayName);
    const { firstname, lastname } = nameObj || {};
    const analyticsObj = {
      name: user.displayName,
      email: user.email,
      firstname: firstname || '',
      lastname: lastname || '',
      track_preferences: onboardingData.interested_racetracks.join(', '),
      persona: onboardingData.user_type.join(', '),
      isPaid: true,
      lastPaid: new Date()
    };
    setNewUser(analyticsObj);
  };

  const navToDashboard = () => {
    props.history.push('/dashboard');
  };

  const onCloseModal = () => {
    const { onClosePaymentModal } = props;
    if (onClosePaymentModal) onClosePaymentModal();
  };

  const handleSaveCardCheckboxChange = (e) => {
    const isSavingCard = e.target.checked;
    setCardState({ ...cardDetails, isSavingCard: isSavingCard });
    trackActivity('payment: save card', { isCardSave: isSavingCard });
  };

  const handlePaymentMethodChange = (isChecked, card) => {
    if (isChecked) {
      if (!card) {
        setCardState({ ...cardDetails, isUseNewCard: true, selectedCard: undefined });
      }
      else {
        setCardState({ ...cardDetails, selectedCard: card, isUseNewCard: false });
        trackActivity('payment: card selected', { 'card Details': card.card.last4 });
      }
    }
  }

  const renderErrors = () => {
    const { resError } = props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  };

  const renderCardLoading = () => {
    return <p className='loading-text'>Loading...</p>;
  }

  const renderCardElement = () => {
    return (
      <>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#fff',
                '::placeholder': {
                  color: 'rgba(255,255,255,0.5)'
                }
              },
              invalid: {
                color: '#FF0000'
              }
            },
            hidePostalCode: true
          }}
        />
        <div className='w-embed mt15'>
          <label className='checkbox-field'>
            Save Card
            <input
              type='checkbox'
              name='checkbox'
              checked={cardDetails.isSavingCard}
              onChange={handleSaveCardCheckboxChange}
            />
            <span className='checkbox-span-rem'></span>
          </label>
        </div>
      </>
    )
  }

  const renderSavedCardsList = () => {
    return (
      <>
        {cardDetails.savedCards.length > 0 && cardDetails.savedCards.map((item, i) => {
          const isChecked = cardDetails.selectedCard ? cardDetails.selectedCard.paymentMethod === item.paymentMethod : false
          return (
            <div className='saved-card-item' key={item.paymentMethod}>
              <label htmlFor={'saved_card_' + i} className='radio-field'>
                <span className='radio-field-value'> <span className='card-name-box'>{`${item.card.brand}`}</span> **** **** **** {`${item.card.last4}`}</span>
                <input
                  type='radio'
                  id={'saved_card_' + i}
                  name={'saved_card_' + i}
                  checked={isChecked}
                  onChange={(e) => { handlePaymentMethodChange(e.target.checked, item) }}
                />
                <span className='radio-span'></span>
              </label>
            </div>
          )
        })}
        <div className='saved-card-item-last'>
          <label htmlFor={'use_new_card'} className='radio-field'>
            Use New Card
                <input
              type='radio'
              id={'use_new_card'}
              name={'use_new_card'}
              checked={cardDetails.isUseNewCard}
              onChange={(e) => { handlePaymentMethodChange(e.target.checked) }}
            />
            <span className='radio-span'></span>
          </label>
        </div>
      </>
    )
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className='payment-model-wrapper d-block'>
      <div className='payment-model-inner'>
        <form onSubmit={handleSubmit}>
          <div className='payment-model-block'>
            <div id='card-element' className='payment-model-top nopadding'>
              {cardDetails.loading ? (
                renderCardLoading()
              ) : (
                  <>
                    {cardDetails.savedCards && renderSavedCardsList()}
                    <div className='saved-card-item-new'>
                      {(!cardDetails.savedCards || cardDetails.isUseNewCard) && renderCardElement()}
                    </div>
                  </>
                )}
            </div>
            <div className='alert-box'>{renderErrors()}</div>
            <div className='payment-btn-block'>
              <button
                type='button'
                className='btn-cancel'
                disabled={props.loading || cardDetails.loading}
                onClick={onCloseModal}>
                Cancel
              </button>
              <div
                className={classNames('form-btn-block', {
                  'mc-loading': props.loading === true || cardDetails.loading === true
                })}>
                <button
                  type='submit'
                  className='btn-square pay w-button'
                  disabled={!stripe || props.loading || cardDetails.loading || !props.amount || (cardDetails.savedCards && (!cardDetails.isUseNewCard && !cardDetails.selectedCard))}>
                  Pay ${props.amount}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentModal;
