const saddles = [
	{
		number: "1",
		color: "#D91517",
		color_2: "#FCFFFD"
	},
	{
		number: "2",
		color: "#FFFFFF",
		color_2: "#020202"
	},
	{
		number: "3",
		color: "#0200FA",
		color_2: "#FFFFFF"
	},
	{
		number: "4",
		color: "#FAFF05",
		color_2: "#000000"
	},
	{
		number: "5",
		color: "#116325",
		color_2: "#FFFFFF"
	},
	{
		number: "6",
		color: "#010101",
		color_2: "#FDFD09"
	},
	{
		number: "7",
		color: "#FC490D",
		color_2: "#000000"
	},
	{
		number: "8",
		color: "#f26da4",
		color_2: "#020202"
	},
	{
		number: "9",
		color: "#0c8f9c",
		color_2: "#020202"
	},
	{
		number: "10",
		color: "#5a4881",
		color_2: "#FFFFFF"
	},
	{
		number: "11",
		color: "#bcac9d",
		color_2: "#c02e2e"
	},
	{
		number: "12",
		color: "#a0e266",
		color_2: "#020202"
	},
	{
		number: "13",
		color: "#3e2e1f",
		color_2: "#FFFFFF"
	},
	{
		number: "14",
		color: "#794a40",
		color_2: "#fefb07"
	},
	{
		number: "15",
		color: "#979589",
		color_2: "#040303"
	},
	{
		number: "16",
		color: "#9fdbe6",
		color_2: "#f70601"
	},
	{
		number: "17",
		color: "#0200FA",
		color_2: "#FFFFFF"
	},
	{
		number: "18",
		color: "#FAFF05",
		color_2: "#000000"
	},
	{
		number: "19",
		color: "#116325",
		color_2: "#FFFFFF"
	},
	{
		number: "20",
		color: "#010101",
		color_2: "#FDFD09"
	},
	{
		number: "21",
		color: "#FC490D",
		color_2: "#000000"
	}
];

export default saddles;
