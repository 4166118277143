import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearResponseMessage } from '../../actions/messageActions';
import Loading from '../../components/Loading/Loading';
import images from '../../assets/images';
import { getCustomerCardDetails, deleteCustomerPaymentMethod, updateCustomerPaymentMethod } from '../../services/pricingService';
//import { initWebflowReady } from '../../global/Helpers';
import { setPaymentTypesList } from '../../actions/pricingActions';
import EditPaymentTypeModal from './EditPaymentTypeModal';
import { trackActivity } from '../../services/analyticsService';
import { logout } from '../../services/authService';

import ProfileDropDown from '../../components/ProfileDropDown/ProfileDropDown';

class PaymentTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPaymentType: undefined,
            isEditModalVisible: false
        };
    }

    componentDidMount() {
        trackActivity('navigation: manage payments');
        this.props.setPaymentTypesList();
        this.LoadData();
        this.props.clearResponseMessage();
    }

    componentWillUnmount() {
        this.props.clearResponseMessage();
    }

    LoadData = async () => {
        await this.props.getCustomerCardDetails();
        //initWebflowReady();
    };

    onOpenEditPaymentTypeModal = (item) => {
        this.trackAnalyticsEvent(1, item.card.last4);
        this.setState({ selectedPaymentType: item, isEditModalVisible: true });
    }

    onCloseEditPaymentTypeModal = () => {
        this.setState({ selectedPaymentType: undefined, isEditModalVisible: false });
    }

    onDeletePaymentType = async (item) => {
        this.trackAnalyticsEvent(2, item.card.last4);
        const id = item.paymentMethod;
        const result = await this.props.deleteCustomerPaymentMethod(id);
        if (result) {
            this.LoadData();
        }
    }

    onEditSubmitted = async (arg) => {
        const { item, expMonth, expYear } = arg;
        const id = item.paymentMethod;
        const obj = {
            expMonth: Number(expMonth),
            expYear: Number(expYear)
        };
        const result = await this.props.updateCustomerPaymentMethod(id, obj);
        if (result) {
            trackActivity('card: updated successfully', { card: item.card.last4, exp_month: expMonth, exp_year: expYear });
            this.LoadData();
        }
        this.onCloseEditPaymentTypeModal();
    }



    renderErrors() {
        const { resError } = this.props.message;
        if (resError) {
            return <div className='alert-message'>{resError}</div>;
        }
    }

    trackAnalyticsEvent = (type, card) => {
        if (!type) return;
        let postfix;
        if (type === 1) {
            postfix = 'edit';
        } else if (type === 2) {
            postfix = 'delete';
        }
        trackActivity(`card: ${postfix} clicked`, card && { card: card });
    }

    render() {
        const { user } = this.props.auth;
        const { loading, paymentTypeList } = this.props.pricing;
        const { selectedPaymentType, isEditModalVisible } = this.state;

        if (loading && !paymentTypeList) return <Loading />;
        const { resError } = this.props.message;
        return (
            <div className='login-body'>
                <div className='navbar pricing w-nav'>
                    <div className='container w-container'>
                        <div className='nav-inner-home pricing'>
                            <a className='brand-logo-home w-nav-brand'>
                                <img src={images['logo'].default} alt='' />
                            </a>

                            <ProfileDropDown
                                user={user}
                                propsParent={this.props}
                            />

                            <div className='menu-button w-nav-button'>
                                <div className='icon w-icon-nav-menu'></div>
                                <div className='top-line'></div>
                                <div className='middle-line'></div>
                                <div className='bottom-line'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pricing-content normal'>
                    <div className='container w-container'>
                        <div className='transaction-wrapper'>
                            <div className='pricing-head-block'>
                                <h4 className='pricing-title'>Manage Payments</h4>
                            </div>
                            <div className='transaction-list-wp'>
                                <div className='track-list'>
                                    {paymentTypeList &&
                                        paymentTypeList.length > 0 &&
                                        paymentTypeList.map((item) => {
                                            const card = item.card || {};
                                            return (
                                                <div key={String(item.paymentMethod)}>
                                                    <div className='track-item'>
                                                        <span className='card-name-box'>{card.brand}</span>
                                                        <div className='track-info trans'>
                                                            <h5 className='track-title'>{`**** **** **** ${card.last4}`}</h5>
                                                        </div>
                                                        <button className='btn-with-icon' disabled={loading} onClick={() => { this.onOpenEditPaymentTypeModal(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                <line x1="16" y1="5" x2="19" y2="8" />
                                                            </svg>
                                                        </button>
                                                        <button className='btn-with-icon' disabled={loading} onClick={() => { this.onDeletePaymentType(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FF0000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                                <line x1="4" y1="7" x2="20" y2="7" />
                                                                <line x1="10" y1="11" x2="10" y2="17" />
                                                                <line x1="14" y1="11" x2="14" y2="17" />
                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal */}

                {
                    isEditModalVisible && (
                        <EditPaymentTypeModal
                            selectedPaymentType={selectedPaymentType}
                            resError={resError}
                            loading={loading}
                            {...this.props}
                            onEditSubmitted={this.onEditSubmitted}
                            onCloseEditPaymentTypeModal={this.onCloseEditPaymentTypeModal}
                        />
                    )
                }
                {this.renderErrors()}
            </div>
        );
    }
}

PaymentTypes.propTypes = {
    message: PropTypes.object.isRequired,
    pricing: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    message: state.message,
    pricing: state.pricing,
    auth: state.auth
});

export default connect(mapStateToProps, {
    getCustomerCardDetails,
    clearResponseMessage,
    deleteCustomerPaymentMethod,
    setPaymentTypesList,
    updateCustomerPaymentMethod,
    logout
})(PaymentTypes);
