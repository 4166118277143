import React from 'react';
import Dashboard from '../DashboardV4/Dashboard';

// gcloud storage buckets update gs://trip-note-pros-admin --cors-file=cors.json
/*
[
    {
      "origin": ["https://dashbaord.tripnotepros.com","https://www.tripnotepros.com", "http://localhost:3000/"],
      "method": ["GET"],
      "responseHeader": ["Content-Type: application/json; charset=utf-8"],
      "maxAgeSeconds": 7200
    }
]
*/

const getUserProfile = () => {
	return Promise.resolve({
		displayName: "Joe Horseplayer",
		firstName: "Joe",
		lastName: "Horseplayer",
		email: "andrew707@gmail.com",
		id: "MkKWSc9PFiX6Zel7XDtpKbCssHz2",
		language: "en"
	});
};

const getRaceData = async () => {

    let data;
	try {
        const res = await fetch('https://storage.googleapis.com/trip-note-pros-admin/js/sample-dashboard-data.json');
        if (res.ok)
             data = await res.json();
    } catch (e) {
        alert('oops...Error getting sample data');
        console.log(e);
    }

	return data.data;
}

function SampleDashboard ({history}) {
    return (
    <>
      <Dashboard
        history={history}
        getUserProfileOverRide={getUserProfile}
        getRaceDataOverRide={getRaceData}
      />
    </>
  );
}

export default SampleDashboard;