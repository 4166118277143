import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import images from '../../assets/images';
import { logout, updateUserProfile, getUserProfile } from '../../services/authService';
//import { getRaceData as getRaceDataDB} from '../../services/dashboardServiceDB';
import { getRaceData } from '../../services/dashboardServiceDB';

import Loading from '../../components/Loading/Loading';
import ProfileDropDown from '../../components/ProfileDropDown/ProfileDropDown';
import HorseItem from './HorseItem';
import Notes from './Notes';
import RaceDateDropDowns from './RaceDateDropDowns';

// AW new   how do you display error?
//import { clearResponseMessage, setErrorMessage } from '../../actions/messageActions';

import { trackActivity } from '../../services/analyticsService';
import languages from '../../data/languages';
import saddles from '../../data/saddles';
import { isStirng } from '../../global/Helpers';

//import axios from 'axios';
//import { REACT_APP_APIURL } from '../../global/Environment';
//let raceDataProm = axios.get(`${REACT_APP_APIURL}/race-v2/v3`)


class Dashboard extends Component {

	constructor(props) {

		super(props);

		this.state = {
			selectedTrack: '',
			trackDates: [],
			selectedRaceDate: undefined,
			trackDateRaces: [],
			selectedRace: {},
			horses: [],
			selectedHorse: undefined,
			selectedNote: undefined,
			prevHorse: undefined,
			nextHorse: undefined,
			horseIndex: 0,
			showRightPanel: true
    	};

        //this.sampleUser = undefined;
	}

	componentDidMount() {
        trackActivity('navigation: dashboard');
		this.LoadData();
	}

	LoadData = async () => {

        let user;
        if (this.props.getUserProfileOverRide)
            user = this.props.dashboard.sampleUser = await this.props.getUserProfileOverRide();
        else 
            user = await this.props.getUserProfile();
        

        let raceData;
        if(this.props.getRaceDataOverRide) 
            raceData = this.props.dashboard.raceData = await this.props.getRaceDataOverRide(user.language);
        else
            raceData = await this.props.getRaceData(user.language);
        
		if (raceData) {
			if (! raceData[0]) {
				this.props.history.push('/pricing');
				return;
			}
			this.setTopLevelState();
		} else {
			if(raceData===false) {
                if(this.props.message.resError){
                    alert(this.props.message.resError)
                    //this.props.setErrorMessage(this.props.message.resError);
                    return
                }
				// could either error or expired session
			}
		}
	};

	// --- 1. for initial page rendering and/or on tracks dropdown : --
	// ---    idx is track idx:
	setTopLevelState = (idx=0) => {
		const { raceData } = this.props.dashboard;
        //const { raceData } = this.state;
		if (! raceData[idx])
			return;

            // extracting from the server data object:
		const { track, dates } = raceData[idx];
		const { races }       = dates[0] || [];
		let { horses }      = dates[0]?.races[0] || [];

		let selectedHorse = horses[0] || null;

		if (selectedHorse)
			selectedHorse.saddle = this.getSaddle(selectedHorse);

		let hasPrevHorse = false;
		let hasNextHorse = horses.length > 1;

        const selectedRace = dates[0].races[0] || [];
		
		this.setState({
			selectedTrack:    track,
			trackDates:       dates,
			selectedRaceDate: dates[0].date,
			trackDateRaces:   races,
			selectedRace:     selectedRace,
			horses:           horses,
			selectedHorse:    selectedHorse,
			selectedNote:	  selectedHorse.notes[0],
			hasPrevHorse: hasPrevHorse,
			hasNextHorse: hasNextHorse,
		}, () => {
            // for demo dashboard
            if(this.props.getRaceIdxToHighlight) {
                let featuredRaceIdx = this.props.getRaceIdxToHighlight()
                this.setRaceNumbersList(featuredRaceIdx);
            }
        });

/* 		this.trackAnalyticsActions(1, {
			track: track,
			date: dates[0].date,
			race: selectedRace.race_number,
			horse: selectedHorse.name,
		}); */
	};

	// --- 2. for dates on dropdowndown: --
	// ---    idx is track-date idx
	setTrackDatesList = (idx = 0) => {

		if (typeof idx === 'undefined')
			return;

		const { trackDates } = this.state;
		const { horses }  = trackDates[idx]?.races[0] || [];

		const selectedHorse   = horses[0] || null;

		if (selectedHorse)
			selectedHorse.saddle = this.getSaddle(selectedHorse);

		let hasPrevHorse = false;
		let hasNextHorse = horses.length > 1;

		this.setState({
			selectedRaceDate: trackDates[idx].date,
			trackDateRaces:   trackDates[idx].races || [],
			selectedRace:     trackDates[idx].races[0] || [],
			horses:           horses,
			selectedHorse:    selectedHorse,
			selectedNote:     selectedHorse.notes[0],
			hasPrevHorse: hasPrevHorse,
			hasNextHorse: hasNextHorse,
		});
        /*
		this.trackAnalyticsActions(2, {
			date: trackDates[idx].date,
			race: trackDates[idx].races[0].race_number,
			horse: selectedHorse.name,
		});
        */
	};

	// --- 3. for horizontal on race numbers bar: --
	setRaceNumbersList = (idx = 0) => {
		let {
			selectedRace,
			trackDateRaces,
			showRightPanel
		} = this.state;


		if (idx === null) {
			showRightPanel = false;
			idx = trackDateRaces.findIndex((x) => x.race_id === selectedRace.race_id);
			if( ! (idx !== -1 &&  idx < trackDateRaces.length+1)) {
				idx = 0;
			}
		}
        
		const horses = trackDateRaces[idx]?.horses || [];
        const selectedHorse   = horses[0] || null;

		if (selectedHorse)
			selectedHorse.saddle = this.getSaddle(selectedHorse);

		let hasPrevHorse = false;
		let hasNextHorse = horses.length > 1;

		this.setState({
			horses: horses,
			selectedRace: trackDateRaces[idx],
			selectedHorse:    selectedHorse,
			selectedNote:     selectedHorse?.notes[0],
			hasPrevHorse: hasPrevHorse,
			hasNextHorse: hasNextHorse,

			showRightPanel: showRightPanel
		});

/* 		this.trackAnalyticsActions(3, {
			race: trackDateRaces[idx]?.race_number,
			horse: horses[0]?.name
		}); */
        
  	};

	// --- 4. for horses on horse click : --
	setHorsesBox = (idx = 0) => {

		let { horses, showRightPanel } = this.state;

		const selectedHorse = horses[idx] || false;

		if (!selectedHorse)
			return;

		showRightPanel = true;

		if (selectedHorse)
			selectedHorse.saddle = this.getSaddle(selectedHorse);

		let selectedNote = selectedHorse.notes[0];

		let hasPrevHorse = idx > 0;
		let hasNextHorse = idx < horses.length - 1;

		this.setState(
			{
				selectedHorse,
				selectedNote,
				hasPrevHorse,
				hasNextHorse,
				horseIndex: idx,
				showRightPanel,
			}
		);
        /*
		this.trackAnalyticsActions(4, {
			horse: selectedHorse.name
		});
        */
	};

	onPrevNextHorse = (type) => {
		const {
			hasPrevHorse,
			hasNextHorse,
			horseIndex
		} = this.state;

		if (type === 'prev') {
			if (hasPrevHorse) {
				this.setHorsesBox(horseIndex - 1);
			}
		} else if (type === 'next') {
			if (hasNextHorse) {
				this.setHorsesBox(horseIndex + 1);
			}
		}
	};

	setNote = (idx,element) => {
		const {
			selectedHorse
		} = this.state;

		this.setState({
			selectedNote: selectedHorse.notes[idx]
		})
	}

  	getSaddle = (selectedHorse) => {
		const {horseNumDisplay, horseNumber}  = this.getHorseNumber(selectedHorse.number, selectedHorse.number_str);
		const saddle = saddles.find((x) => Number(x.number) === Number(horseNumber)) || {};
		return saddle;
  	}

	getHorseNumber = (number, numberStr) => {
		let horseNumDisplay = 	(numberStr || number) + '';
		let horseNumber = parseInt(horseNumDisplay.replace(/\D/g,''));
		return {horseNumDisplay, horseNumber}
	}


	onLanguageChange = async (language) => {
		if (!language || !isStirng(language))
			return;

		const result = await this.props.updateUserProfile({ language });

		if (result) {
			this.LoadData();
		}
	};

	onPurchaseClick = () => {
		this.props.history.push('/pricing');
	};


/*
	"horses": [
		{
			"id": 39257,
			"name": "Anacapa"
			"number": 1,
				"notes": [
					{
						"date": "May 6 2023"
						"track": "Aqueduct"
						"note": "Test note Anacapa at Ague"
						"summary": "Summary"
						"updown": "n/c"
					{

*/


/* 	trackAnalyticsActions = (type, props) => {
		if (!type)
			return;

		const what = {
			1:'track selected',
			2:'race selected',
			3:'horse selected'
		};

		if(!props.track)
			props.track = this.state.selectedTrack;

		if(!props.date)
			props.date = this.state.selectedRaceDate;

		if(!props.race)
			props.race = this.state.selectedRace.race_number;

		let user = {user: this.props.auth.user.email};

		trackActivity('dashboard: ' + what[type], {...user, ...props});
	}; */


	render() {

        let  { user, userProfile } = this.props.auth;

		const { raceData, sampleUser } = this.props.dashboard;

		const {
			selectedTrack,
			trackDates,
			trackDateRaces,
			selectedRaceDate,
			selectedRace,
			horses,
			selectedHorse,
			selectedNote,
			hasPrevHorse,
			hasNextHorse,
			showRightPanel,
		} = this.state;

		const loading_auth = this.props.auth.loading;
		const loading_dashboard = this.props.dashboard.loading;
		const loading = loading_auth || loading_dashboard;

		if (loading || !raceData[0])
			return <Loading />;

		const currentLanguage = userProfile ? userProfile.language : 'en';

    return (
		<div className='dashboard-body'>
			<div className='main-wrapper'>
				<div className='header-block'>
					<div className='navbar-dashboard w-nav'>
						<div className='nav-inner'>

							<a className='brand-logo w-nav-brand'>
								<img src={images['triplogo'].default} alt='' />
							</a>

							<div className='dropdown-list-wrap'>

								<RaceDateDropDowns
									data={raceData}
									field='track'
									selectedItem={selectedTrack}
									handler={this.setTopLevelState}
								/>

								<div className='dropdown-list-point'></div>

								<RaceDateDropDowns
									data={trackDates}
									field='date'
									selectedItem={selectedRaceDate}
									handler={this.setTrackDatesList}
								/>
							</div>


							<div className='nav-top-menubar'>
								<button type='button' className='nav-link w-nav-link ctm' onClick={this.onPurchaseClick}>
									Buy Notes
								</button>
							</div>


							<ProfileDropDown
								user={sampleUser || user}
								propsParent={this.props}
							/>


							{currentLanguage &&
							!! languages.length && languages.map((item) => {
								if (currentLanguage !== item.id)
									return (
										<div
											className='language-block'
											key={item.id}
											onClick={() => {
												this.onLanguageChange(item.id);
											}}>

											<a>{item.value}</a>
										</div>
									);
								else return null;
							})}

						</div>
					</div>
				</div>


				<div className='content-wrapper'>
					<div className='content-body'>
						<div className='content-leftside'>

							{/*-- Horizontal race numbers-- */}
                            <div className='race-tab-wrapper'>
								{trackDateRaces?.length > 0 && trackDateRaces.map((item,idx) => (
									<div
									key={item.race_number}
									className={classNames('racing-tab', {
									current: selectedRace?.race_number === item?.race_number
									})}
									onClick={() => {
										this.setRaceNumbersList(idx);
									}}>
									RACE {item.race_number}
									</div>
								))}
							</div>

                            {/*-- BEGIN horses -- */}
							<div className='race-content'>
								<div className='race-box-wp'>

									{!!horses.length && horses.map((item, idx) => {

										const {horseNumDisplay, horseNumber}  = this.getHorseNumber(item.number, item.number_str);
										const saddle = saddles.find((x) => Number(x.number) === horseNumber) || {};

										item.number = horseNumDisplay;

										return (
											<HorseItem
												key={item.id}
												item={item}
												name={item.name}
												saddle={saddle}
												selectedHorse={selectedHorse}
                                                grade={item?.notes[0]?.grade || 0}
												onSetHorsesBox={() => {
                                                    this.setHorsesBox(idx);
												}}
											/>
										);
									})}

								</div>
							</div>

						</div>
						 {/*--END horses -- */}

                        {/*-- BEGIN content-rightbar -- */}
                        <div className='content-rightbar' style={{ visibility: showRightPanel ? 'visible' : 'hidden' }}>

                            <div className='rightbar-head-block'
                                style={{
                                    backgroundColor: selectedHorse?.saddle.color,
                                    color: selectedHorse?.saddle.color_2
                                }}>

                                <h3 className='rightbar-head-text'>
                                {selectedHorse?.number} { selectedHorse?.name }                                   
                                </h3>

                                <div className='close-btn' onClick={()=>{this.setRaceNumbersList(null)}}>
                                    <img src={images['closebk'].default} alt='' />
                                </div>

                            </div>

                            <Notes
                                notes={ selectedHorse?.notes }
                                selectedNote={ selectedNote || null }
                                onNotesClick={ this.setNote }
                            />

                            <div className='rightbar-button'>
                                <button type='button' disabled={!hasPrevHorse} className='btn primary' onClick={()=>{this.onPrevNextHorse('prev')}}>
                                    previous
                                </button>
                                <button type='button' disabled={!hasNextHorse} className='btn primary' onClick={()=>{this.onPrevNextHorse('next')}}>
                                    Next
                                </button>
                            </div>
                        </div>
                        {/*-- END content-rightbar -- */}
			        </div>
		        </div>
	        </div>
        </div>
    );
  }
}

/*
// Set default props
Dashboard.defaultProps = {
    raceDataNew: [],
  };
*/

Dashboard.propTypes = {
	auth: PropTypes.object.isRequired,
	message: PropTypes.object.isRequired,
	dashboard: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	message: state.message,
	dashboard: state.dashboard
});

export default connect(mapStateToProps, {
	logout,
	getRaceData,
	getUserProfile,
	updateUserProfile
})(Dashboard);


//export default connect(mapStateToProps, {getRaceDataDB})(Dashboard);
